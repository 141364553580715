<template>
	<div id="app">
			 <router-view></router-view> 
	
	</div>
</template>

<script>

	
	export default {
		name: 'App',
		data() {
			return{
				network: '',
				lang: true,
				pagedata:{},
				tabIndex:0
			}
		},
		components:{
		
		},
		async beforeMount() {
			console.log(window.screen.height, window.screen.width)
			
		},
		updated() {
			let _this = this;
			
		},
		async mounted() {
			
		},
		methods:{
			changeTab:function(index){
				this.tabIndex = index;
			}
		}
	}
</script>

<style>
	@font-face {
		font-family: "gamefont";
		src: url("assets/gamefont.ttf") format('woff');
	}
	input{
		outline:none;
	}
	html,body{
		padding: 0;
		margin: 0;
		font-family: 'gamefont';
		font-weight: 500;
		/* background: #000; */
	}
	
	#app{
		width: 100%;
		position: absolute;
		margin: 0 auto;
		
	}
	.account{
		color: #fff;
		display: flex;
		justify-content: space-between;
		padding: 26px;
	}
	

</style>
