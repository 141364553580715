import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import { routes } from './router/index.js'
import store from './store/index.js'
import lang from './lang/lang.js'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';
import JSEncrypt from 'jsencrypt';
import VueClipboard from 'vue-clipboard2'
// import 'animate.css';
Vue.use(VueClipboard)
Vue.use(VueCookies);
Vue.prototype.$cookie = VueCookies;
Vue.prototype.$lang = lang;
Vue.config.productionTip = false

Vue.use(VueRouter);

// 创建 VueRouter 实例，并传入 routes
let router = new VueRouter({
  mode: 'history',  // 保持使用 hash 模式
  routes: routes       // 只传递 routes 数组，不要传递整个 routes 对象
})
router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.path}`);
  next();
});

// Vue.directive('px-to-vw', {
//   inserted(element) {
//     // 获取元素的行内样式
//     const style = element.style.cssText;
//     // 正则表达式匹配 px 单位
//     const newStyle = style.replace(/(\d+)px/g, (match, p1) => pxToVw(Number(p1)));
//     // 设置转换后的样式
//     element.style.cssText = newStyle;
//   }
// })

(function() {
  const baseWidth = 750; // 移动端设计稿宽度 750px
  const baseFontSize = 10; // 基准字体大小，1rem = 100px（1rem = 10px）

  // 判断是否为移动设备
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const setFontSize = () => {
    const clientWidth = document.documentElement.clientWidth;
    
    if (isMobile) {
      // 如果是移动设备，动态设置根元素的 font-size
      const scale = clientWidth / baseWidth; // 根据设计稿宽度比例调整
      document.documentElement.style.fontSize = `${baseFontSize * scale}px`; // 设置根元素字体大小
    } else {
      // 如果是PC设备，保持常规布局，font-size 为默认的 16px
      document.documentElement.style.fontSize = '16px';
    }
  };

  // 初始化时调用
  setFontSize();

  // 当窗口大小变化时重新设置字体大小
  window.addEventListener('resize', setFontSize);
})();


new Vue({
  store,
  router,     // 使用配置好的路由实例
  render: h => h(App),
}).$mount('#app')
